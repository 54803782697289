import React, { useState } from "react";
import { BlockWrapper } from "../blockWrapper";
import { useProjectQuery } from "../../../GraphQl/useProjectQuery";
import { CenteredCarousel } from "../../carousels";
import Image from "gatsby-plugin-sanity-image";
import { Link } from "gatsby";
import cx from "classnames";
import { motion } from "framer-motion";
import { useLocation } from "@reach/router";

const CarouselItem = ({ item, activeSlide, index }) => {
    const { slug, title, meta, projectType } = item;

    return (
        <Link
            to={`/projects/${slug && slug.current}`}
            style={{ flex: "0 0 auto" }}
            className="relative group text-center  w-[80%] md:w-[60%] pl-3  md:pl-14"
        >
            <div className="aspect-w-6 aspect-h-4 lg:aspect-w-10 lg:aspect-h-6 relative overflow-hidden">
                <motion.div
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.5 }}
                    layout="position"
                    className="absolute inset-0 object-cover brightness-[40%]"
                >
                    {meta && meta.metaImage && (
                        <Image className="w-full h-full" {...meta.metaImage} />
                    )}
                </motion.div>
            </div>
            <div
                className={cx(
                    "absolute py-5 lg:py-[50px] flex flex-col items-center justify-between top-0 bottom-0 w-[97%]  text-warm-white bg-dark bg-opacity-20 opacity-100 transition-all duration-200 font-bold pointer-events-none",
                    {
                        "opacity-0": activeSlide !== index,
                    },
                )}
            >
                <h5 className="text-[14px] sm:text-[19px] lg:text-[20px] tracking-[0.02em] leading-[114%] ">
                    {projectType?.title ? projectType?.title : " "}
                </h5>
                <h4 className="text-xl md:text-4xl ">{title}</h4>
                <h5 className="opacity-100 lg:opacity-0 transition-all duration-200 lg:group-hover:opacity-100 blockH8 text-teal-01 underline underline-offset-2 ">
                    View Work
                </h5>
            </div>
        </Link>
    );
};

const OtherProjectsBlock = ({ blockConfig, title, yellowBackground }) => {
    const { projects } = useProjectQuery();
    const [activeSlide, setActiveSlide] = useState(0);
    const { pathname } = useLocation();

    // extract project slug from pathname
    const slug = pathname.split("/")[2];

    // filter out current project
    const filteredProjects = projects.filter(
        (item) => item.slug.current !== slug,
    );

    return (
        <BlockWrapper className="relative bg-warm-white" {...blockConfig}>
            <h2 className="text-teal blockH3 text-center mb-[32px]  lg:mb-[50px] font-bold">
                {title ? title : "Other Projects"}
            </h2>

            <div className="relative ">
                <CenteredCarousel
                    setActiveSlide={setActiveSlide}
                    centered={true}
                    className=""
                >
                    {filteredProjects.map((item, i) => (
                        <CarouselItem
                            item={item}
                            activeSlide={activeSlide}
                            index={i}
                        />
                    ))}
                </CenteredCarousel>
            </div>
            <div
                className={cx("z-0 absolute left-0 w-full h-1/3 bg-teal-01 ", {
                    "bottom-0": yellowBackground === "bottom",
                    "top-0": yellowBackground === "top",
                    hidden: yellowBackground === "none" || !yellowBackground,
                })}
            />
        </BlockWrapper>
    );
};

export default OtherProjectsBlock;
