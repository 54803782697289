import React from "react";

const TitleBlock = ({ title }) => {
    return (
        <div className="pt-[150px] pb-[65px]  md:pt-[260px] md:pb-[150px] bg-warm-white">
            <h2 className="text-teal blockH2 text-center">{title}</h2>
        </div>
    );
};

export default TitleBlock;
