import React from "react";
import Image from "gatsby-plugin-sanity-image";
import cx from "classnames";

import { BlockWrapper } from "../blockWrapper";
import { PortableTextBlock } from "../../sanity/portableTextBlock";

const ImageText = ({ text, image, blockConfig, layout, theme }) => {
    let themeColor = theme ? theme.colorlist.title : "";
    let blockClasses = "";
    if (themeColor === "Teal") {
        blockClasses = "bg-teal text-warm-white";
    } else if (themeColor === "Medium teal") {
        blockClasses = "bg-teal-01 text-warm-white";
    } else if (themeColor === "Warm white") {
        blockClasses = "bg-warm-white text-teal";
    }

    return (
        <div className={` ${blockClasses}`}>
            <BlockWrapper className={`grid grid-cols-14`} {...blockConfig}>
                <div
                    className={cx(" self-center", {
                        "col-start-2  col-end-14   md:col-end-8":
                            layout === "left" || !layout,
                        "col-start-2  md:col-start-8 col-end-14":
                            layout === "right",
                    })}
                >
                    {image && <Image {...image} />}
                </div>
                <div
                    className={cx(
                        `blockH9 max-w-none row-start-1 self-center  ${blockClasses}`,
                        {
                            "col-start-2 col-end-14 md:col-start-9 ":
                                layout === "left" || !layout,
                            "col-start-2 col-end-14 md:col-start-2 md:col-end-7 ":
                                layout === "right",
                        },
                    )}
                >
                    {text && (
                        <PortableTextBlock
                            text={text}
                            className="prose prose-lg md:prose-2xl"
                        />
                    )}
                </div>
            </BlockWrapper>
        </div>
    );
};

export default ImageText;
