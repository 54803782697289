import { graphql, useStaticQuery } from "gatsby";

import { QueryFragments } from "./queryFragments"; // eslint-disable-line

export const useTeamQuery = () => {
    const { allSanityTeamMember } = useStaticQuery(graphql`
        query {
            allSanityTeamMember(sort: { fields: order }) {
                teamMembers: nodes {
                    name
                    slug {
                        current
                    }
                    bio
                    phone
                    role
                    email
                    image {
                        ...Image
                    }
                }
            }
        }
    `);
    return allSanityTeamMember || {};
};
