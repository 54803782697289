import React from "react";
import Image from "gatsby-plugin-sanity-image";
import { BlockWrapper } from "../blockWrapper";
import { AltHeadingTextBlock } from "../../sanity/altHeadingTextBlock";
import { PortableTextBlock } from "../../sanity/portableTextBlock";

import { GuideDownload } from "../../forms/guideDownload";
const GuideForm = ({ heading, text, cover, guideFile }) => {
    return (
        <div className="bg-teal-01">
            <BlockWrapper className="grid grid-cols-14 gap-y-8 lg:gap-y-10 text-warm-white py-12 lg:py-24 xl:py-32 2xl:py-40 overflow-x-hidden">
                <div className="row-start-1 col-start-2 col-end-14 flex  flex-wrap md:flex-nowrap  ">
                    {heading && (
                        <AltHeadingTextBlock
                            className="text-warm-white blockH3 leading-none w-full mb-6 md:mb-20 md:max-w-[70%] "
                            text={heading}
                        />
                    )}
                </div>
                <div className=" row-start-2 col-start-2 col-end-14 md:col-end-8">
                    {text && (
                        <AltHeadingTextBlock
                            className="text-warm-white  blockH7 w-full mb-20"
                            text={text}
                        />
                    )}

                    <GuideDownload guideFile={guideFile} className="" />
                </div>
                {cover && (
                    <Image
                        className="row-start-3 md:row-start-2 col-start-2 col-end-14 md:col-start-10"
                        {...cover}
                    />
                )}
            </BlockWrapper>
        </div>
    );
};

export default GuideForm;
