import React from "react";
import PropTypes from "prop-types";

import {
    BannerFull,
    TextBlock,
    TextBlockTwoCol,
    ImageText,
    ImageBlock,
    VideoBlock,
    ImageSlider,
    Cta,
    ThreeCols,
    OurWork,
    Testimonials,
    BannerSimple,
    ContactForm,
    PeopleGrid,
    GuideForm,
    FeaturedButtons,
    ImageHeading,
    ThreeImageBlock,
    ServiceBlock,
    ProjectsGrid,
    OtherProjectsBlock,
    CenteredSlider,
    TitleBlock,
    DeliverableOutcomeBlock,
} from "./blocks";

const BlockZone = ({ blocks }) => {
    const block = blocks.map((block) => {
        switch (block._type) {
            case "bannerFull":
                return <BannerFull {...block} />;
            case "bannerSimple":
                return <BannerSimple {...block} />;
            case "contactForm":
                return <ContactForm {...block} />;
            case "guideForm":
                return <GuideForm {...block} />;
            case "textBlock":
                return <TextBlock {...block} />;
            case "imageText":
                return <ImageText {...block} />;
            case "imageHeading":
                return <ImageHeading {...block} />;
            case "imageBlock":
                return <ImageBlock {...block} />;
            case "videoBlock":
                return <VideoBlock {...block} />;
            case "textBlockTwoCol":
                return <TextBlockTwoCol {...block} />;
            case "imageSlider":
                return <ImageSlider {...block} />;
            case "cta":
                return <Cta {...block} />;
            case "threeCols":
                return <ThreeCols {...block} />;
            case "peopleGrid":
                return <PeopleGrid {...block} />;
            case "ourWork":
                return <OurWork {...block} />;
            case "testimonials":
                return <Testimonials {...block} />;
            case "featuredButtons":
                return <FeaturedButtons {...block} />;
            case "serviceBlock":
                return <ServiceBlock {...block} />;
            case "threeImageBlock":
                return <ThreeImageBlock {...block} />;
            case "projectsGrid":
                return <ProjectsGrid {...block} />;
            case "otherProjectsBlock":
                return <OtherProjectsBlock {...block} />;
            case "centeredSlider":
                return <CenteredSlider {...block} />;
            case "titleBlock":
                return <TitleBlock {...block} />;
            case "deliverableOutcomeBlock":
                return <DeliverableOutcomeBlock {...block} />;
            default:
                return null;
        }
    });
    return <>{block}</>;
};

BlockZone.propTypes = {
    blocks: PropTypes.array.isRequired,
};

export default BlockZone;
