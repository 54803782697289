import React from "react";
import { BlockWrapper } from "../blockWrapper";
import Image from "gatsby-plugin-sanity-image";

const ThreeImageBlock = ({ images, blockConfig }) => {
    return (
        <BlockWrapper className="relative z-50" {...blockConfig}>
            <div className="grid grid-cols-3 w-full h-auto">
                {images?.map((image, index) => (
                    <Image className="w-full h-full" {...image} key={index} />
                ))}
            </div>
        </BlockWrapper>
    );
};

export default ThreeImageBlock;
