import React from "react";
import Image from "gatsby-plugin-sanity-image";
import { Link } from "gatsby";
import { motion } from "framer-motion";

const ServiceBlock = ({ items }) => {
    return (
        <div className="px-gutter relative bg-warm-white">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-x-5 gap-y-5 z-20">
                {items.map((item, index) => (
                    <Link
                        className="w-full h-full relative"
                        to={`/services/${item?.slug?.current}`}
                    >
                        <div className="w-full h-full aspect-w-1 aspect-h-1 relative z-20 overflow-hidden">
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                transition={{ duration: 0.5 }}
                                layout="position"
                                className="w-full h-full"
                            >
                                <Image
                                    {...item?.meta?.metaImage}
                                    className="brightness-[40%]  w-full h-full "
                                />
                            </motion.div>
                        </div>
                        <h2 className="text-center z-30  px-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-warm-white blockH4 font-bold w-full">
                            {item?.title}
                        </h2>
                    </Link>
                ))}
            </div>
            <div className="z-0 absolute bottom-0 left-0 w-full h-[85%] md:h-1/2 bg-teal-01" />
        </div>
    );
};

export default ServiceBlock;
