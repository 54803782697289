import React from "react";
import { motion } from "framer-motion";
import Image from "gatsby-plugin-sanity-image";
import { HeadingTextBlock } from "../../sanity/headingTextBlock";
import { BannerVideo } from "../../media/bannerVideo";
import cx from "classnames";

const zVars = {
    hidden: {
        opacity: 1,
        x: "5%",
    },
    visible: {
        opacity: 1,
        x: 0,
    },
};

const textVars = {
    hidden: {
        opacity: 0,
        y: 10,
    },
    visible: {
        opacity: 1,
        y: 0,
    },
};

const BannerSimple = ({ text, image, videoUrl, showDiagonal }) => {
    return (
        <div className="bg-warm-white md:pb-14">
            <motion.div
                initial="hidden"
                animate="visible"
                variants={zVars}
                transition={{
                    duration: 0.7,
                }}
                className="md:flex md:items-center"
            >
                {!image && videoUrl && (
                    <BannerVideo
                        video={videoUrl}
                        className="md:w-[1084px] z-0 px-5 pt-5 md:px-10 md:py-10 xl:w-1/2 xl:pr-0"
                    />
                )}
                {image && (
                    <Image
                        className="md:w-7/12 h-full z-0 px-5 pt-5 md:px-10 md:py-10 xl:w-1/2 xl:pr-0"
                        {...image}
                    />
                )}

                {text && (
                    <div className="text-teal pt-5 pb-10 px-5 -mt-10 md:mt-0 md:p-0 md:-ml-28 md:pr-5 lg:p-8 xl:py-12 relative">
                        <motion.div
                            variants={textVars}
                            initial="hidden"
                            animate="visible"
                            transition={{
                                delay: 0.7,
                                duration: 0.4,
                            }}
                        >
                            <HeadingTextBlock
                                className={cx("bannerH1 w-full md:w-50% ")}
                                text={text}
                            />
                        </motion.div>
                    </div>
                )}
            </motion.div>
        </div>
    );
};

export default BannerSimple;
