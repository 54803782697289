import React from "react";
import Image from "gatsby-plugin-sanity-image";
import { BlockWrapper } from "../blockWrapper";
import cx from "classnames";

const ImageBlock = ({ blockConfig, image, layout, yellowBackground }) => {
    let blockClasses = "";
    if (layout === "left") {
        blockClasses = "col-start-2 col-end-14 lg:col-end-12 2xl:col-end-10";
    } else if (layout === "right") {
        blockClasses = "col-start-2 col-end-14 lg:col-start-4 2xl:col-start-6";
    } else if (layout === "center") {
        blockClasses = "col-start-2 col-end-14 ";
    } else if (layout === "full") {
        blockClasses = "col-start-1 col-end-15";
    }

    return (
        <div className="relative bg-warm-white">
            <BlockWrapper
                {...blockConfig}
                className="grid grid-cols-14 relative"
            >
                <div className={`relative w-full h-full ${blockClasses} `}>
                    {image && (
                        <Image
                            className="block w-full h-auto max-h-screen object-cover relative  z-10"
                            {...image}
                        />
                    )}
                </div>
            </BlockWrapper>
            <div
                className={cx(
                    "z-0 absolute left-0 w-full h-[85%] md:h-1/2 bg-teal-01 ",
                    {
                        "bottom-0": yellowBackground === "bottom",
                        "top-0": yellowBackground === "top",
                        hidden:
                            yellowBackground === "none" || !yellowBackground,
                    },
                )}
            />
        </div>
    );
};

export default ImageBlock;
