import React from "react";
import Image from "gatsby-plugin-sanity-image";
import { StandardCarousel } from "../../carousels";
import { Link } from "gatsby";
import cx from "classnames";
import { BlockWrapper } from "../blockWrapper";

const SliderImage = ({ url, blockImage, header }) => {
    return (
        <Link
            to={url ? `/${url?.slug?.current}` : null}
            className="w-3/4 lg:w-1/2 relative"
            style={{ flex: "0 0 auto" }}
        >
            <div className="aspect-w-3 aspect-h-2 relative">
                {blockImage && (
                    <Image
                        className={cx("absolute inset-0 object-cover", {
                            "brightness-50": header,
                        })}
                        {...blockImage}
                    />
                )}
            </div>
            {header && (
                <h2 className="absolute blockH5 text-warm-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ">
                    {header}
                </h2>
            )}
        </Link>
    );
};

const ImageSlider = ({ theme, items, blockConfig }) => {
    return (
        <BlockWrapper
            {...blockConfig}
            className="overflow-x-hidden bg-warm-white"
        >
            <StandardCarousel>
                {items?.map((item) => (
                    <SliderImage {...item} />
                ))}
            </StandardCarousel>
        </BlockWrapper>
    );
};

export default ImageSlider;
