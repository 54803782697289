import React, { useState } from "react";
import { CenteredCarousel } from "../../carousels";
import Image from "gatsby-plugin-sanity-image";
import cx from "classnames";
import { motion } from "framer-motion";

const CarouselItem = ({ item, activeSlide, index }) => {
    const { slideText, title, slideImage } = item;

    return (
        <div
            style={{ flex: "0 0 auto" }}
            className="relative group text-center w-[80%] md:w-[60%] pl-3  md:pl-14 "
        >
            <div className="aspect-w-6 aspect-h-4 lg:aspect-w-10 lg:aspect-h-6 relative overflow-hidden w-full h-full">
                <motion.div
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.5 }}
                    layout="position"
                    className="absolute inset-0 object-cover brightness-[40%] w-full h-full"
                >
                    {slideImage && (
                        <Image className="w-full h-full" {...slideImage} />
                    )}
                </motion.div>
            </div>
            <div
                className={cx(
                    "absolute py-5 lg:py-[50px]  flex flex-col items-center justify-between top-0 bottom-0 w-[97%] text-warm-white bg-dark bg-opacity-20 opacity-100 transition-all duration-1000 font-bold space-y-4 pointer-events-none",
                    {
                        "opacity-0": activeSlide !== index,
                    },
                )}
            >
                <h5 className="blockH8 text-warm-white">{title}</h5>
                <p className="text-[14px] sm:text-[22px] lg:text-[24px] tracking-[0.02em]  leading-[140%] px-5 lg:px-40 text-warm-white">
                    {slideText}
                </p>
                <div className="bg-transparent h-[1px]" />
            </div>
        </div>
    );
};

const CenteredSlider = ({ items, title }) => {
    const [activeSlide, setActiveSlide] = useState(0);

    return (
        <div className="bg-warm-white">
            <h2 className="text-teal blockH3 text-center mb-[73px] font-bold">
                {title}
            </h2>
            <div className="relative  ">
                <CenteredCarousel
                    setActiveSlide={setActiveSlide}
                    centered={true}
                    className=""
                >
                    {items?.map((item, i) => (
                        <CarouselItem
                            item={item}
                            activeSlide={activeSlide}
                            index={i}
                        />
                    ))}
                </CenteredCarousel>
            </div>
        </div>
    );
};

export default CenteredSlider;
