import React from "react";
import PortableText from "react-portable-text";

const serializers = {
    h3: (props) => <h2 className="" {...props} />,
    normal: (props) => <h2 className="" {...props} />,
    strong: (props) => <span className="font-bold" {...props} />,
}; // eslint-disable-line

export const AltHeadingTextBlock = ({ text, ...props }) => {
    return <PortableText content={text} serializers={serializers} {...props} />;
};
