import React from "react";
import Image from "gatsby-plugin-sanity-image";
import { BlockWrapper } from "../blockWrapper";
import { AltHeadingTextBlock } from "../../sanity/altHeadingTextBlock";

const ImageHeading = ({ text, image }) => {
    return (
        <BlockWrapper className="relative">
            {image && (
                <Image
                    className="block w-full h-auto object-cover"
                    {...image}
                />
            )}
            {text && (
                <div className="absolute z-10 bottom-gutter xl:bottom-20 left-0 w-full grid grid-cols-14">
                    <AltHeadingTextBlock
                        className="col-start-2 col-end-14 xl:col-start-3 text-warm-white text-4xl lg:text-6xl 2xl:text-8xl"
                        text={text}
                    />
                </div>
            )}
        </BlockWrapper>
    );
};

export default ImageHeading;
