import React from "react";
import { AltHeadingTextBlock } from "../../sanity/altHeadingTextBlock";
import { BlockWrapper } from "../blockWrapper";
import { motion } from "framer-motion";

const aniVars = {
    hidden: {
        y: 20,
        scale: 0.96,
    },
    visible: {
        y: 0,
        scale: 1,
    },
};

const Cta = ({ heading, supportingText, blockConfig, theme }) => {
    let themeColor = theme ? theme.colorlist.title : "";
    let blockClasses = "";
    if (themeColor === "Teal") {
        blockClasses = "bg-teal text-warm-white";
    } else if (themeColor === "Medium teal") {
        blockClasses = "bg-teal-01 text-warm-white";
    } else if (themeColor === "Warm white") {
        blockClasses = "bg-warm-white text-teal";
    }

    return (
        <div className={`${blockClasses}`}>
            <BlockWrapper className={`grid grid-cols-14`} {...blockConfig}>
                <motion.div
                    variants={aniVars}
                    transition={{
                        ease: "easeInOut",
                        duration: 1,
                    }}
                    className="col-start-2 col-end-14 md:col-end-10 lg:flex xl:col-start-2 items-center row-start-1 blockH3  mb-4 lg:mb-0 text-left md:text-left"
                >
                    {heading && (
                        <AltHeadingTextBlock
                            className={` blockH3 ${blockClasses}`}
                            text={heading}
                        />
                    )}
                </motion.div>
                <motion.div
                    variants={aniVars}
                    transition={{
                        duration: 1,
                    }}
                    className="col-start-2 col-end-14 md:col-start-10 row-start-2 lg:row-start-1 text-right lg:flex lg:flex-wrap justify-end items-center mt-10 lg:mt-0 "
                >
                    {supportingText && (
                        <AltHeadingTextBlock
                            className={`blockH7 ${blockClasses}`}
                            text={supportingText}
                        />
                    )}
                </motion.div>
            </BlockWrapper>
        </div>
    );
};

export default Cta;
