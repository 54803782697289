import React from "react";
import { FeaturedLinksDark, FeaturedLinksBrand } from "../../ui/featuredLinks";

const FeaturedButtons = ({ theme }) => {
    let themeColor = theme ? theme.colorlist.title : "";
    let blockClasses = "";
    if (themeColor === "Teal") {
        blockClasses = "bg-teal text-warm-white";
    } else if (themeColor === "Medium teal") {
        blockClasses = "bg-teal-01 text-warm-white";
    } else if (themeColor === "Warm white") {
        blockClasses = "bg-warm-white text-teal";
    }

    return (
        <div className={`grid grid-cols-14 ${blockClasses}`}>
            <div className="col-start-2 col-end-14 xl:col-start-3 xl:col-end-13 flex justify-end">
                {themeColor === "Warm white" ? (
                    <FeaturedLinksBrand />
                ) : (
                    <FeaturedLinksDark />
                )}
            </div>
        </div>
    );
};

export default FeaturedButtons;
