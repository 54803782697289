import { graphql, useStaticQuery } from "gatsby";

import { QueryFragments } from "./queryFragments"; // eslint-disable-line

export const useProjectQuery = () => {
    const { allSanityProject } = useStaticQuery(graphql`
        query {
            allSanityProject(sort: { fields: order }) {
                projects: nodes {
                    title
                    slug {
                        current
                    }
                    meta {
                        metaImage {
                            ...Image
                        }
                    }
                    projectType {
                        title
                    }
                }
            }
        }
    `);
    return allSanityProject || {};
};
