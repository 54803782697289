import React from "react";
import Image from "gatsby-plugin-sanity-image";
import { useTeamQuery } from "../../../GraphQl/useTeamQuery";
import { Link } from "gatsby";
import { BlockWrapper } from "../blockWrapper";
import { motion, AnimateSharedLayout } from "framer-motion";
import cx from "classnames";

const PeopleGrid = ({ blockConfig, items }) => {
    const { teamMembers } = useTeamQuery();

    const [activeIndex, setActiveIndex] = React.useState(null);

    const rows = Math.ceil(teamMembers.length / 3);

    const heightFraction = rows * 2;
    const heightPercentage = (1 / heightFraction) * 100;
    const heightString = `${heightPercentage}%`;

    return (
        <BlockWrapper {...blockConfig} className=" bg-warm-white">
            <div className="px-gutter grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-5 relative">
                <div
                    className="z-0 absolute top-0 left-0 w-full  bg-teal-01"
                    style={{ height: heightString }}
                />
                {teamMembers.map((item, i) => {
                    const { name, role, image, slug } = item;
                    return (
                        <Link
                            to={`/about/${slug?.current}`}
                            onMouseEnter={() => setActiveIndex(i)}
                            onMouseLeave={() => setActiveIndex(null)}
                            className="col-span-1   text-warm-white relative"
                        >
                            <div className="relative ">
                                <div className="aspect-h-8 aspect-w-7 relative  overflow-hidden">
                                    {image && (
                                        <Image
                                            className={cx(
                                                "absolute inset-0 object-cover transition-all duration-1000  ease-in-out ",
                                                {
                                                    "brightness-[90%]":
                                                        activeIndex !== i,
                                                    "brightness-50":
                                                        activeIndex === i,
                                                },
                                            )}
                                            {...image}
                                        />
                                    )}
                                </div>
                            </div>
                            <AnimateSharedLayout>
                                <div className="px-6 text-center w-full h-auto font-bold   absolute bottom-11  left-0 z-20 ">
                                    <motion.div layout="position">
                                        {name && (
                                            <h4 className="blockH6 mb-5">
                                                {name}
                                            </h4>
                                        )}
                                        {role && (
                                            <h5 className="blockH10 mb-5">
                                                {role}
                                            </h5>
                                        )}
                                    </motion.div>

                                    {activeIndex === i && (
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            transition={{ duration: 0.8 }}
                                            className=" underline underline-offset-2  text-raspberry blockH10 "
                                        >
                                            <div className=" underline underline-offset-2  text-raspberry blockH10 ">
                                                Read more
                                            </div>
                                        </motion.div>
                                    )}
                                </div>
                            </AnimateSharedLayout>
                        </Link>
                    );
                })}
            </div>
        </BlockWrapper>
    );
};

export default PeopleGrid;
