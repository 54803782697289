import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
// Hooks
import { usePadTop, usePadBottom } from "../../hooks";

const aniVars = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
    },
};

export const BlockWrapper = ({
    children,
    className,
    paddingTop,
    paddingBottom,
    slug,
    rest,
}) => {
    const padTop = usePadTop(paddingTop);
    const padBottom = usePadBottom(paddingBottom);

    const controls = useAnimation();
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true,
        containScroll: "keepSnaps",
    });

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    return (
        <motion.section
            className={`${className} ${padTop} ${padBottom} overflow-hidden `}
            id={slug && slug.current}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={aniVars}
            {...rest}
        >
            <div ref={ref} />
            {children}
        </motion.section>
    );
};
