import React from "react";
import { motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import { BlockWrapper } from "../blockWrapper";
import { AltHeadingTextBlock } from "../../sanity/altHeadingTextBlock";
import { PortableTextBlock } from "../../sanity/portableTextBlock";
import cx from "classnames";

const aniVars = {
    hidden: {
        opacity: 0,
        y: 25,
    },
    visible: {
        opacity: 1,
        y: 0,
    },
};

const TextBlock = ({
    heading,
    subheading,
    text,
    centerText,
    blockConfig,
    theme,
    paddingRight,
}) => {
    let themeColor = theme ? theme.colorlist.title : "";
    let blockClasses = "";
    if (themeColor === "Teal") {
        blockClasses = "bg-teal text-warm-white";
    } else if (themeColor === "Medium teal") {
        blockClasses = "bg-teal-01 text-warm-white";
    } else if (themeColor === "Warm white") {
        blockClasses = "bg-warm-white text-teal";
    }

    return (
        <div className={`${blockClasses}`}>
            <BlockWrapper {...blockConfig}>
                <div className="grid grid-cols-14">
                    {(heading || subheading) && (
                        <motion.div
                            variants={aniVars}
                            transition={{ duration: 0.3, delay: 0.2 }}
                            className={cx(
                                "row-start-1 col-start-2 col-end-14 lg:col-end-13",
                                {},
                            )}
                        >
                            {heading && (
                                <AltHeadingTextBlock
                                    className={` blockH2 ${blockClasses}`}
                                    text={heading}
                                />
                            )}

                            {subheading && (
                                <div
                                    className={`flex items-center my-[42px]  gap-4 ${blockClasses}`}
                                >
                                    <>
                                        <AltHeadingTextBlock
                                            className="blockH7 h-full w-auto"
                                            text={subheading}
                                        />
                                    </>
                                </div>
                            )}
                        </motion.div>
                    )}
                    {text && (
                        <motion.div
                            variants={aniVars}
                            transition={{ duration: 0.3, delay: 0.2 }}
                            className={cx(
                                "row-start-2 col-start-2 col-end-14   ",
                                {
                                    "xl:col-end-14": !paddingRight,
                                    "xl:col-end-11": paddingRight,
                                },
                            )}
                        >
                            <PortableTextBlock
                                className={`  max-w-none prose prose-lg md:prose-2xl ${
                                    centerText && "text-center"
                                } ${blockClasses}`}
                                text={text}
                            />
                        </motion.div>
                    )}
                </div>
            </BlockWrapper>
        </div>
    );
};

export default TextBlock;
