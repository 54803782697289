import React, { useState } from "react";
import Image from "gatsby-plugin-sanity-image";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import { CenteredCarousel } from "../../carousels";
import cx from "classnames";

import { HeadingTextBlock } from "../../sanity/headingTextBlock";

import Arrow from "../../../svg/arrow.svg";

const CarouselItem = ({ item, activeSlide, index }) => {
    const { slug, title, meta, projectType } = item;
    return (
        <Link
            to={`/projects/${slug && slug.current}`}
            style={{ flex: "0 0 auto" }}
            className="relative group text-center w-[80%] md:w-[60%] pl-3  md:pl-14"
        >
            <div className="aspect-w-6 aspect-h-4 lg:aspect-w-10 lg:aspect-h-6 relative overflow-hidden ">
                <motion.div
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.5 }}
                    layout="position"
                    className="absolute inset-0 object-cover brightness-[40%]"
                >
                    {meta && meta.metaImage && (
                        <Image
                            className=" w-full h-full "
                            {...meta.metaImage}
                        />
                    )}
                </motion.div>
            </div>

            <div
                className={cx(
                    "absolute flex flex-col items-center justify-between py-5 lg:py-[50px] top-0 bottom-0 w-[97%] lg:translate-x-7 text-warm-white bg-dark bg-opacity-20 opacity-100 transition-all duration-200 pointer-events-none",
                    {
                        "opacity-0": activeSlide !== index,
                    },
                )}
            >
                <h4 className="blockH8 text-warm-white">
                    {projectType ? projectType : ""}
                </h4>
                <h4 className="uppercase blockH5 mt-12">{title}</h4>
                <div className="opacity-100 lg:opacity-0 transition-all duration-200 lg:group-hover:opacity-100  text-xl mt-4 underline text-warm-white hover:text-raspberry  ">
                    View Work
                </div>
            </div>
        </Link>
    );
};
const OurWork = ({ title, subHeading, enticerText, image, items }) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const [footerHover, setFooterHover] = useState(false);

    return (
        <div className="grid grid-cols-14 text-warm-white bg-warm-white overflow-x-hidden w-full">
            {image && (
                <div className="col-start-1 col-end-15 row-start-1 z-0  aspect-w-6 aspect-h-6 md:aspect-h-4 xl:aspect-h-3 relative">
                    <Image
                        className="inset-0 w-full h-full absolute object-cover"
                        {...image}
                    />
                </div>
            )}
            {(title || subHeading || enticerText) && (
                <div className="col-start-1 col-end-15 row-start-1 h-full w-full bg-black bg-opacity-40 z-10"></div>
            )}
            <div className="col-start-2 col-end-14 xl:col-end-13 z-20 row-start-1 flex flex-col flex-wrap lg:flex-nowrap lg:items-start justify-center pb-8 md:pb-12 lg:pb-20 py-20 lg:py-0">
                <div className="flex flex-col flex-wrap lg:flex-nowrap text-warm-white">
                    {title && (
                        <h4 className=" max-w-[200px] blockH1">{title}</h4>
                    )}
                    {subHeading && (
                        <div className="z-10 flex items-center  lg:gap-4 pt-[21px]">
                            <div>
                                <HeadingTextBlock
                                    className="blockH7 items-end h-full w-auto uppercase"
                                    text={subHeading}
                                />
                            </div>
                        </div>
                    )}
                </div>
                {enticerText && (
                    <Link
                        className="blockH8 mt-10 flex hover:text-raspberry transition-all duration-200 "
                        to="/work"
                        onMouseEnter={() => setFooterHover(true)}
                        onMouseLeave={() => setFooterHover(false)}
                    >
                        {enticerText}{" "}
                        <span class="pl-[10px] self-center pt-[1px]">
                            <Arrow
                                className={cx("", {
                                    "svg-raspberry": footerHover,
                                })}
                            />
                        </span>
                    </Link>
                )}
            </div>
            <div className="row-start-3 col-start-1 col-end-15 pt-4 lg:pt-[54px] relative ">
                <CenteredCarousel
                    setActiveSlide={setActiveSlide}
                    centered={true}
                    className=""
                >
                    {items.map((item, i) => (
                        <CarouselItem
                            item={item}
                            activeSlide={activeSlide}
                            index={i}
                        />
                    ))}
                </CenteredCarousel>
                <div className="bg-warm-white absolute top-0 left-0 w-full h-1/2 z-0" />
            </div>
        </div>
    );
};

export default OurWork;
