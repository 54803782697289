import React from "react";
import { BlockWrapper } from "../blockWrapper";
import { AltHeadingTextBlock } from "../../sanity/altHeadingTextBlock";
import { PortableTextBlock } from "../../sanity/portableTextBlock";
import { motion } from "framer-motion";

const columnVars = {
    hidden: {
        opacity: 0,
        y: 25,
    },
    visible: {
        opacity: 1,
        y: 0,
    },
};

const headingVars = {
    hidden: {
        opacity: 0,
        x: 25,
    },
    visible: {
        opacity: 1,
        x: 0,
    },
};

const ThreeCols = ({ heading, subheading, items, theme, blockConfig }) => {
    let themeColor = theme?.colorlist?.title;
    let blockClasses = "";
    if (themeColor === "Teal") {
        blockClasses = "bg-teal text-warm-white";
    } else if (themeColor === "Medium teal") {
        blockClasses = "bg-teal-01 text-warm-white";
    } else if (themeColor === "Warm white") {
        blockClasses = "bg-warm-white text-teal";
    }

    return (
        <BlockWrapper
            {...blockConfig}
            className={`p-0 m-0 grid grid-cols-14 py-[60px] sm:py-[100px] lg:py-[140px] ${blockClasses}`}
        >
            <div
                className={`col-start-2 col-end-14 xl:col-start-2 xl:col-end-14`}
            >
                <motion.div
                    variants={headingVars}
                    transition={{
                        duration: 0.4,
                    }}
                    className="col-span-3  "
                >
                    {heading && (
                        <AltHeadingTextBlock
                            className="blockH2"
                            text={heading}
                        />
                    )}

                    {subheading && (
                        <motion.div
                            variants={headingVars}
                            transition={{
                                delay: 0.4,
                                duration: 0.4,
                            }}
                            className="mt-5 md:mt-[43px]"
                        >
                            <AltHeadingTextBlock
                                className="blockH7 h-full md:w-auto"
                                text={subheading}
                            />
                        </motion.div>
                    )}
                </motion.div>
                <div className="grid grid-cols-1 lg:grid-cols-3 grid-flow-row gap-4 gap-y-10 lg:gap-8  lg:gap-y-16 mt-14">
                    {items?.map((item, index) => {
                        let delay = index * 0.4;
                        return (
                            <motion.div
                                variants={columnVars}
                                transition={{
                                    delay: delay,
                                    duration: 0.4,
                                }}
                                className="col-span-1 "
                            >
                                {item.text && (
                                    <PortableTextBlock
                                        className="blockH9"
                                        text={item.text}
                                    />
                                )}
                            </motion.div>
                        );
                    })}
                </div>
            </div>
        </BlockWrapper>
    );
};

export default ThreeCols;
