import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { BlockWrapper } from "../blockWrapper";
import { AltHeadingTextBlock } from "../../sanity/altHeadingTextBlock";
import { PortableTextBlock } from "../../sanity/portableTextBlock";

const TextBlockTwoCol = ({
    heading,
    subheading,
    textLeft,
    textRight,
    centerText,
    blockConfig,
    theme,
}) => {
    let themeColor = theme ? theme.colorlist.title : "";
    let blockClasses = "";
    if (themeColor === "Teal") {
        blockClasses = "bg-teal text-warm-white";
    } else if (themeColor === "Medium teal") {
        blockClasses = "bg-teal-01 text-warm-white";
    } else if (themeColor === "Warm white") {
        blockClasses = "bg-warm-white text-teal";
    }

    return (
        <BlockWrapper className={`${blockClasses}`} {...blockConfig}>
            <div className="grid grid-cols-14 ">
                {(heading || subheading) && (
                    <div className="blockH3 row-start-1 col-start-2 col-end-14 xl:col-end-13 md:flex flex-wrap lg:flex-nowrap items-end mb-6 lg:mb-12">
                        {heading && (
                            <AltHeadingTextBlock
                                AltHeadingTextBlock={`${blockClasses}`}
                                text={heading}
                            />
                        )}

                        {subheading && (
                            <div
                                className={`flex items-center mt-4 mb-12 lg:mb-0 lg:ml-4 gap-4 ${blockClasses}`}
                            >
                                <div class="hidden lg:block">
                                    <StaticImage
                                        className="h-full w-auto"
                                        src="../../../images/diagonal.png"
                                        alt={subheading}
                                    />
                                </div>
                                <AltHeadingTextBlock
                                    className="xl:text-2xl h-full w-auto"
                                    text={subheading}
                                />
                            </div>
                        )}
                    </div>
                )}
                {textLeft && (
                    <PortableTextBlock
                        className={`row-start-2 col-start-2 col-end-14 lg:col-end-8 lg:pr-5 max-w-none ${
                            centerText && "text-center"
                        } ${blockClasses}`}
                        text={textLeft}
                    />
                )}
                {textRight && (
                    <PortableTextBlock
                        className={`row-start-3 col-start-2 col-end-14 mt-6 lg:mt-0 lg:row-start-2 lg:col-start-8 lg:pl-5  prose prose-lg max-w-none ${
                            centerText && "text-center"
                        } ${blockClasses}`}
                        text={textRight}
                    />
                )}
            </div>
        </BlockWrapper>
    );
};

export default TextBlockTwoCol;
