import React from "react";

import { BlockWrapper } from "../blockWrapper";
import { VideoModule } from "../../modules";

const VideoBlock = ({ blockConfig, video, allowPlay, layout }) => {
    return (
        <BlockWrapper
            className={layout && "px-gutter bg-warm-white"}
            {...blockConfig}
        >
            <VideoModule allowPlay={allowPlay} {...video} />
        </BlockWrapper>
    );
};

export default VideoBlock;
