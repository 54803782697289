import React from "react";
import { useLocation } from "@reach/router";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { navigate } from "gatsby";

function encode(data) {
    return Object.keys(data)
        .map(
            (key) =>
                encodeURIComponent(key) + "=" + encodeURIComponent(data[key]),
        )
        .join("&");
}

export const Contact = ({ className }) => {
    const { pathname } = useLocation();

    const itemWrapperClasses = "relative col-span-1";

    const labelClasses =
        "text-white text-base lg:text-xl 2xl:text-2xl font-light block mb-4";

    const inputClasses =
        "block appearance-none rounded-none shadow-none w-full mb-8 py-4 px-5 text-base outline-remove bg-teal-04 text-teal placeholder-teal-01";

    const errorClasses =
        "tracking-wide text-sm my-2 pl-5 text-left text-warm-white absolute bottom-0 left-0";

    return (
        <div className={`${className}`}>
            <Formik
                initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    company: "",
                    comments: "",
                }}
                validate={(values) => {
                    const errors = {};
                    if (!values.firstName) {
                        errors.firstName = "First Name is Required";
                    }

                    if (!values.email) {
                        errors.email = "Email address is Required";
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                            values.email,
                        )
                    ) {
                        errors.email = "Invalid email address";
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    fetch(pathname || "/", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        body: encode({
                            "form-name": `contact`,
                            form_name: `contact`,
                            ...values,
                        }),
                    })
                        .then(() => {
                            setSubmitting(false);
                            setStatus("success");
                            navigate(`/submission-confirmation`);
                        })
                        .catch((error) => alert(error));
                }}
            >
                {({
                    onChange,
                    isSubmitting,
                    status,
                    values,
                    /* and other goodies */
                }) => (
                    <Form
                        id="Contact-Form"
                        className={`w-full relative`}
                        name="contact"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        subject="Contact"
                    >
                        {/* Hidden Fields for netlify */}
                        <input type="hidden" name="form-name" value="contact" />
                        <p hidden>
                            <label>
                                <input name="bot-field" onChange={onChange} />
                            </label>
                        </p>
                        <>
                            <div
                                className={`text-warm-white prose lg:prose-xl text-left transition-all delay-300 duration-300 absolute top-0 left-0 right-0 z-10 ${
                                    status === "success"
                                        ? "opacity-100 pointer-events-all"
                                        : "opacity-0 pointer-events-none"
                                }`}
                            >
                                <p>
                                    Thanks for contacting us, we will be in
                                    touch as soon as we can.
                                </p>
                            </div>
                            <div
                                className={`grid gap-x-12 grid-cols-1 font-body text-medium transition-all duration-300 ${
                                    status === "success" &&
                                    "opacity-0 pointer-events-none"
                                }`}
                            >
                                <div className={itemWrapperClasses}>
                                    <Field
                                        type="text"
                                        name="company"
                                        className={inputClasses}
                                        placeholder="Company Name"
                                    />
                                </div>
                                <div className={itemWrapperClasses}>
                                    <Field
                                        type="email"
                                        name="email"
                                        className={inputClasses}
                                        placeholder="Email Address"
                                    />
                                    <ErrorMessage
                                        name="email"
                                        className={errorClasses}
                                        component="div"
                                    />
                                </div>
                                <div className={itemWrapperClasses}>
                                    <Field
                                        type="text"
                                        name="phone"
                                        className={inputClasses}
                                        placeholder="Phone Number"
                                    />
                                </div>
                                <div className={itemWrapperClasses}>
                                    <Field
                                        type="text"
                                        name="firstName"
                                        className={inputClasses}
                                        placeholder="First Name"
                                    />
                                    <ErrorMessage
                                        name="firstName"
                                        className={errorClasses}
                                        component="div"
                                    />
                                </div>
                                <div className={itemWrapperClasses}>
                                    <Field
                                        type="text"
                                        name="lastName"
                                        className={inputClasses}
                                        placeholder="Last Name"
                                    />
                                    <ErrorMessage
                                        name="lastName"
                                        className={errorClasses}
                                        component="div"
                                    />
                                </div>
                                <div className={itemWrapperClasses}>
                                    <Field
                                        name="comments"
                                        className={inputClasses}
                                        placeholder="Comments"
                                        as="textarea"
                                        rows="12"
                                    />
                                </div>
                                <div
                                    className={`flex justify-end mt-4 ${itemWrapperClasses}`}
                                >
                                    <button
                                        className=" px-10 block w-auto border border-teal rounded-nonw submit font-medium py-4 transition-all duration-200 tracking-wider text-lg xl:text-xl bg-teal text-warm-white hover:bg-raspberry hover:text-warm-white"
                                        type="submit"
                                    >
                                        SUBMIT
                                    </button>
                                </div>
                            </div>
                        </>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
