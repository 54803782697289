import React from "react";
import { BlockWrapper } from "../blockWrapper";
import { useProjectQuery } from "../../../GraphQl/useProjectQuery";
import Image from "gatsby-plugin-sanity-image";
import { Link } from "gatsby";
import { motion } from "framer-motion";

const ProjectsGrid = ({ blockConfig }) => {
    const { projects } = useProjectQuery();

    // calculate the number of rows of projects in three column grid
    const rows = Math.ceil(projects.length / 3);

    const heightFraction = rows * 2;
    const heightPercentage = (1 / heightFraction) * 100;
    const heightString = `${heightPercentage}%`;

    return (
        <div className="bg-warm-white">
            <BlockWrapper {...blockConfig}>
                <div className=" relative ">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-x-5 gap-y-5 z-20 relative px-gutter">
                        {projects.map((item, index) => (
                            <Link
                                to={`/projects/${item?.slug?.current}`}
                                className="w-full h-full relative group"
                            >
                                <div className="w-full h-full aspect-w-1 aspect-h-1 relative z-20 flex overflow-hidden">
                                    <motion.div
                                        whileHover={{ scale: 1.1 }}
                                        transition={{ duration: 0.5 }}
                                        layout="position"
                                        className="w-full h-full object-cover "
                                    >
                                        <Image
                                            {...item?.meta?.metaImage}
                                            className="brightness-[40%] w-full h-full relative object-cover"
                                        />
                                    </motion.div>
                                </div>
                                <h2 className="text-center z-30 px-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2   text-warm-white blockH6 font-bold w-full">
                                    {item?.title}
                                </h2>
                                <div className="opacity-0 transition-all duration-200 group-hover:opacity-100 text-center underline underline-offset-2 z-30 px-10 absolute bottom-[15%] left-1/2 -translate-x-1/2 text-raspberry blockH9 font-bold w-full">
                                    View Work
                                </div>
                            </Link>
                        ))}
                        <div
                            className={` absolute z-10 bottom-0 left-0 w-full  bg-teal-01`}
                            style={{ height: heightString }}
                        />
                    </div>
                </div>
            </BlockWrapper>
        </div>
    );
};

export default ProjectsGrid;
