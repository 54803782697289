import React from "react";
import { BlockWrapper } from "../blockWrapper";
import { AltHeadingTextBlock } from "../../sanity/altHeadingTextBlock";
import { PortableTextBlock } from "../../sanity/portableTextBlock";

import { Contact } from "../../forms/contact";
const ContactForm = ({ heading, addresses }) => {
    return (
        <div className="bg-teal-01">
            <BlockWrapper className="grid grid-cols-14 gap-y-8 lg:gap-y-16  py-12 text-warm-white lg:py-24 overflow-x-hidden">
                {addresses && (
                    <PortableTextBlock
                        className="row-start-1 col-start-2 col-end-14 md:col-end-6    text-warm-white"
                        text={addresses}
                    />
                )}
                <div className="col-start-2 md:col-start-6  col-end-14">
                    {heading && (
                        <AltHeadingTextBlock
                            text={heading}
                            className="blockH7 font-bold pb-11"
                        />
                    )}
                    <Contact className="" />
                </div>
            </BlockWrapper>
        </div>
    );
};

export default ContactForm;
