import React from "react";
import { BlockWrapper } from "../blockWrapper";
import Image from "gatsby-plugin-sanity-image";
import { StaticImage } from "gatsby-plugin-image";
import { HeadingTextBlock } from "../../sanity/headingTextBlock";
import { PortableTextBlock } from "../../sanity/portableTextBlock";
import { MotionCarousel } from "../../carousels";

const CarouselItem = ({ item }) => {
    const { itemText, imageLeft, imageRight } = item;
    return (
        <div className="" style={{ flex: "0 0 auto" }}>
            <div>
                <PortableTextBlock
                    className="prose lg:prose-lg"
                    text={itemText}
                />
                test
            </div>
        </div>
    );
};
const Testimonials = ({ heading, subheading, blockConfig, items }) => {
    return (
        <div className="bg-teal-01 text-warm-white">
            <BlockWrapper
                className={`grid grid-cols-1 grid-flow-row overflow-hidden`}
                {...blockConfig}
            >
                <div
                    id="testTop"
                    className="col-span-1 px-gutter flex flex-col items-start mb-12 lg:mb-20"
                >
                    {subheading && (
                        <div className="flex items-center  relative">
                            <HeadingTextBlock
                                className="blockH7 uppercase font-bold text-warm-white"
                                text={subheading}
                            />
                        </div>
                    )}
                    {heading && (
                        <HeadingTextBlock className="blockH2" text={heading} />
                    )}
                </div>
                <MotionCarousel className="col-span-1">
                    {items.map((item) => {
                        const { itemText, featuredImage, heading } = item;

                        return (
                            <div
                                className="w-full"
                                style={{ flex: "0 0 auto" }}
                            >
                                <div className="w-full px-gutter lg:px-0 flex flex-col-reverse lg:grid grid-cols-14 gap-y-8">
                                    <div className=" col-start-2 col-end-10 flex items-center mt-10 lg:mt-0">
                                        {heading && (
                                            <PortableTextBlock
                                                className="blockH9 text-warm-white max-w-none pr-gutter lg:pr-20"
                                                text={heading}
                                            />
                                        )}
                                    </div>
                                    {featuredImage && (
                                        <div className="col-start-10 col-end-14  relative w-full h-ful ">
                                            <Image
                                                className=""
                                                {...featuredImage}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </MotionCarousel>
            </BlockWrapper>
        </div>
    );
};

export default Testimonials;
