import React from "react";
import { BlockWrapper } from "../blockWrapper";

const DeliverableOutcomeBlock = ({ blockConfig, doItems }) => {
    return (
        <BlockWrapper {...blockConfig} className="px-gutter bg-warm-white">
            <div>
                <div className="grid grid-cols-2 pb-12 gap-x-2 md:gap-x-24">
                    <h3 className="blockH5 text-teal">Key deliverable</h3>
                    <h3 className="blockH5 text-teal">Key outcome</h3>
                </div>

                <div className="space-y-6">
                    {doItems.map((item, index) => (
                        <div
                            key={index}
                            className="grid grid-cols-2 gap-x-2 md:gap-x-24 blockH9"
                        >
                            <p
                                className={`text-teal border-t border-teal-03 pb-3 pt-3 ${
                                    index === doItems.length - 1
                                        ? "border-b"
                                        : ""
                                }`}
                            >
                                {item?.deliverable}
                            </p>
                            <p
                                className={`text-teal border-t border-teal-03 pb-3 pt-3 ${
                                    index === doItems.length - 1
                                        ? "border-b"
                                        : ""
                                }`}
                            >
                                {item?.outcome}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </BlockWrapper>
    );
};

export default DeliverableOutcomeBlock;
